import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./5.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import "./style.css";
import Zoom from "react-reveal/Zoom";
import { routes } from "../../routes";
export default function Team() {
  const Navigate = useNavigate();
  return (
    <div className="Team">
      <Header />
      <Zoom left>
        {" "}
        <div className="teamEm">
          <img src={img1} alt="" />
          <div className="teamContent">
            <h4>Автушин Федор Петрович</h4>

            <p>Менеджер по недвижимости</p>
            <h4>Проведено</h4>
            <p>150 сделок</p>
            <div className="call" onClick={() => Navigate(routes.form)}>
              Связаться
            </div>
          </div>
        </div>
      </Zoom>
      <Zoom right>
        {" "}
        <div className="teamEm">
          <img src={img2} alt="" />
          <div className="teamContent">
            <h4>Автушин Федор Петрович</h4>

            <p>Менеджер по недвижимости</p>
            <h4>Проведено</h4>
            <p>150 сделок</p>
            <div className="call" onClick={() => Navigate(routes.form)}>
              Связаться
            </div>
          </div>
        </div>
      </Zoom>
      <Zoom left>
        {" "}
        <div className="teamEm">
          <img src={img3} alt="" />
          <div className="teamContent">
            <h4>Автушин Федор Петрович</h4>

            <p>Менеджер по недвижимости</p>
            <h4>Проведено</h4>
            <p>150 сделок</p>
            <div className="call" onClick={() => Navigate(routes.form)}>
              Связаться
            </div>
          </div>
        </div>
      </Zoom>
      <Zoom right>
        {" "}
        <div className="teamEm">
          <img src={img4} alt="" />
          <div className="teamContent">
            <h4>Автушин Федор Петрович</h4>

            <p>Менеджер по недвижимости</p>
            <h4>Проведено</h4>
            <p>150 сделок</p>
            <div className="call" onClick={() => Navigate(routes.form)}>
              Связаться
            </div>
          </div>
        </div>
      </Zoom>
      <Zoom left>
        {" "}
        <div className="teamEm">
          <img src={img5} alt="" />
          <div className="teamContent">
            <h4>Автушин Федор Петрович</h4>

            <p>Менеджер по недвижимости</p>
            <h4>Проведено</h4>
            <p>150 сделок</p>
            <div className="call" onClick={() => Navigate(routes.form)}>
              Связаться
            </div>
          </div>
        </div>
      </Zoom>
    </div>
  );
}
