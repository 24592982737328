import "./App.css";
import { Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import "./fonts/stylesheet.css";
import { routes } from "./routes";
/* import Form from "./Pages/Form"; */
import Services from "./Pages/Services/Services";
import Team from "./Pages/Team";
import Form from "./Pages/Form";
import Objects from "./Pages/Objects";
import { useEffect, useState } from "react";
import { Url } from "./const";
import Thanks from "./Pages/Thanks";
function App() {
  const [objects, setObjects] = useState();
  function getObj() {
    let formData = new FormData();
    formData.append("ID", "22");
    fetch(Url + "/getEscape.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setObjects(data);
      });
  }
  useEffect(() => {
    getObj();
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path={routes.form} element={<Form></Form>} />
        <Route path={routes.services} element={<Services />} />
        <Route path={routes.team} element={<Team />} />
        <Route path={routes.thanks} element={<Thanks />} />
        <Route path={routes.objects} element={<Objects objects={objects} />} />
      </Routes>
    </div>
  );
}

export default App;
