import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { Url } from "../../const";
import { routes } from "../../routes";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Roll from "react-reveal/Roll";
export default function Objects({ objects }) {
  const Navigate = useNavigate();

  return (
    <div className="objects">
      <Header />
      <div className="objList">
        {objects &&
          objects.length > 0 &&
          objects.map((em, index) => {
            let arrHots = em.hots.split(",");

            if (index % 2 == 1) {
              return (
                <Roll left key={index}>
                  <div className="objEm">
                    <div className="hots">
                      {arrHots &&
                        arrHots.length > 0 &&
                        arrHots.map((em, index) => {
                          if (em != "") {
                            if (
                              em.includes("ВНЖ") ||
                              em.includes("ТОП") ||
                              em.includes("Хот")
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="hotEm"
                                  style={{
                                    background: "#FF0F00",
                                    color: "#fff",
                                  }}
                                >
                                  {em}
                                </div>
                              );
                            } else if (
                              em.includes("Взнос") ||
                              em.includes("Рассрочка")
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="hotEm"
                                  style={{
                                    background: "#0283AC",
                                    color: "#fff",
                                  }}
                                >
                                  {em}
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  className="hotEm"
                                  style={{
                                    background: "#FFB800",
                                    color: "#000",
                                  }}
                                >
                                  {em}
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <h4>{em.title}</h4>
                    <div className="rel">
                      <img src={em.photoUrl} alt="" />
                      <div className="priceObj">От {em.price} €</div>
                    </div>
                    <div
                      className="getPr"
                      onClick={() => {
                        Navigate(routes.form);
                      }}
                    >
                      Получить презентацию объекта
                    </div>
                  </div>
                </Roll>
              );
            } else {
              return (
                <Roll right key={index}>
                  <div className="objEm">
                    <h4>{em.title}</h4>
                    <div className="hots">
                      {arrHots &&
                        arrHots.length > 0 &&
                        arrHots.map((em, index) => {
                          if (em != "") {
                            if (
                              em.includes("ВНЖ") ||
                              em.includes("ТОП") ||
                              em.includes("Хот")
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="hotEm"
                                  style={{
                                    background: "#FF0F00",
                                    color: "#fff",
                                  }}
                                >
                                  {em}
                                </div>
                              );
                            } else if (
                              em.includes("Взнос") ||
                              em.includes("Рассрочка")
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="hotEm"
                                  style={{
                                    background: "#0283AC",
                                    color: "#fff",
                                  }}
                                >
                                  {em}
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  className="hotEm"
                                  style={{
                                    background: "#FFB800",
                                    color: "#000",
                                  }}
                                >
                                  {em}
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <div className="rel">
                      <img src={em.photoUrl} alt="" />
                      <div className="priceObj">От {em.price} €</div>
                    </div>
                    <div
                      className="getPr"
                      onClick={() => {
                        Navigate(routes.form);
                      }}
                    >
                      Получить презентацию объекта
                    </div>
                  </div>
                </Roll>
              );
            }
          })}
      </div>
    </div>
  );
}
